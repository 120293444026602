import React from 'react';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import SettingsIcon from '@material-ui/icons/Settings';

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import SpeedIcon from '@material-ui/icons/Speed';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Container from '@material-ui/core/Container';
import FolderList from '../components/FolderList';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import DnsIcon from '@material-ui/icons/Dns';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
    },
    icon: {
      fontSize: 35,
    }
  }));

function valuetext(value) {
return `${value}°C`;
}

function Settings(props) {
    const classes = useStyles(props);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
      });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    
    return (
        <div>
            <TopBar heading='Settings' icon={<SettingsIcon style={{fontSize: 35}}/>} />
            <Toolbar >
                <Container>
                <List className={classes.root}>
            <ListItem>
                <ListItemIcon>
                <SettingsIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Setting 1" />
                <Switch
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedA"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </ListItem>
            <Divider light />

            <ListItem>
                <ListItemIcon>
                <Brightness4Icon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Setting 2" />
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </ListItem>
            <Divider light />

            <ListItem>
                <ListItemIcon>
                <SpeedIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Speed" />
                <Box width="50%">
                <Slider

                    defaultValue={30}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                /></Box>
            </ListItem>
            <Divider light />

            <ListItem>
                <ListItemIcon>
                <DnsIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Speed" />
                <Box width="50%">
                <TextField defaultValue="192.168.178.1" />
                </Box>
            </ListItem>
            <Divider light />

            <ListItem>
                <ListItemIcon>
                <PowerSettingsNewIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Power Off" />
                <Button variant="contained" color="secondary">
                    Shutdown
                </Button>
            </ListItem>

            
            </List>
            </Container>
            </Toolbar>
            

            <BottomNav selection='settings'/>
            
        </div>
    );
}

export default Settings;