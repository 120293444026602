import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    boxStyle: {
      marginRight: '1rem', 
    },
  }); 

function TopBar(props) {
    const classes = useStyles();
    return(
        <AppBar position="sticky">
            <Toolbar>
                <Box className={classes.boxStyle}>{props.icon}</Box>
                <Typography variant="h5" color="inherit">
                {props.heading}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;