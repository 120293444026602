import React from 'react';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import HomeIcon from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import WifiIcon from '@material-ui/icons/Wifi';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import WavesIcon from '@material-ui/icons/Waves';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      marginTop: '1rem',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }));

function Home() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div>
            <TopBar heading='Home' icon={<HomeIcon style={{fontSize: 35}}/>} />

            <Toolbar >
                <Container>
                    <Box alignItems="center" justifyContent="center" display="flex" m="1" p={1}>
                        Connected to Table<Box m={1}><WifiIcon style={{fill: "green"}}/></Box>
                    </Box>
                <Divider light />
                <Card className={classes.root} >
                <CardContent>
                    <Typography component="h5" variant="h5">
                    Status
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    IP Adress: 192.168.178.1<br/>
                    Size: 120x80<br/>
                    Connected as <b>Daniel</b>
                    </Typography>
                </CardContent>
                </Card>
                <Box alignItems="center" justifyContent="center" display="flex" m="0.5" p={1}></Box>
                <Divider light />

                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            Current Job:
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Waves Pattern
                        </Typography>
                        </CardContent>
                        <div className={classes.controls}>
                        <IconButton aria-label="previous">
                            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                        </IconButton>
                        <IconButton aria-label="play/pause">
                            <PlayArrowIcon className={classes.playIcon} />
                        </IconButton>
                        <IconButton aria-label="next">
                            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                        </IconButton>
                        </div>
                    </div>
                    <WavesIcon style={{fontSize: 150}}/>
                    </Card>

                </Container>
            </Toolbar>           


            <BottomNav selection='home'/>
        </div>
    );
}

export default Home;