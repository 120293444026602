import './App.css';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Library from './pages/Library';
import Playlist from './pages/Playlist';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';



class App extends Component {
  constructor(){
    super();
    this.state ={
      redirect: null
    };
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route path="/settings" component={Settings} />
          <Route path="/library" component={Library} />
          <Route path="/playlist" component={Playlist} />
          <Redirect from="/" to="/home"/>
        </Switch>
      </Router>
    )
  }
}

export default App;
