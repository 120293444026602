import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import FavoriteIcon from '@material-ui/icons/Favorite';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import AppleIcon from '@material-ui/icons/Apple';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import CameraIcon from '@material-ui/icons/Camera';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import WavesIcon from '@material-ui/icons/Waves';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    fontSize: 35,
  }
}));

export default function FolderList(props) {
    const [checked, setChecked] = React.useState(true);
    const classes = useStyles(props);
  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <FavoriteIcon className={classes.icon}/>
        </ListItemIcon>
        <ListItemText primary="Heart" secondary="Jan 9, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon />
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <AllInclusiveIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Infinity" secondary="Jan 10, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <ControlPointIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Plus" secondary="Jan 11, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <AppleIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Apple" secondary="Jan 12, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />
      
      <ListItem>
        <ListItemIcon>
          <BlurOnIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Blur" secondary="Jan 13, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <CameraIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Shutter" secondary="Jan 14, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <FingerprintIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Fingerprint" secondary="Jan 15, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>       
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <FilterHdrIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Mountain" secondary="Jan 16, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <FilterDramaIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Cloud" secondary="Jan 17, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <Brightness2Icon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Moon" secondary="Jan 18, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>
      <Divider light />

      <ListItem>
        <ListItemIcon>
          <WavesIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Waves" secondary="Jan 19, 2020" />
        <IconButton edge="end" aria-label="delete">
            <BorderColorIcon/>
        </IconButton>
      </ListItem>            
    </List>
  );
}

FolderList.propTypes = {
  classes: PropTypes.object.isRequired,
};
