import React from 'react';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

export default function Settings() {
    return (
        <div>
            <TopBar heading='Playlist' icon={<PlayCircleOutlineIcon style={{fontSize: 35}}/>} />
            Playlist
            <BottomNav selection='playlist'/>
        </div>
    );
};