import React from 'react';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import FolderList from '../components/FolderList';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

export default function Library() {
    return (
        <div>
            <TopBar heading="Library" icon={<AcUnitIcon style={{fontSize: 35}}/>}/>
            <Toolbar >
                <Container>
                    <FolderList />
                </Container>
            </Toolbar>
            <BottomNav selection='library'/>
        </div>
    );
};

