import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
    stickToBottom: {
      width: '100%',
      bottom: 0,
      background: '#eeeeee',
    },
    offset: theme.mixins.toolbar,
  })); 

export default function BottomNav(props) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(props.selection);
  return ( 
    <React.Fragment>
    <div className={classes.offset} />   
    <AppBar position="fixed" color="primary" style={{top: 'auto', bottom: 0}}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          history.push('/'+newValue);
        }}
        showLabels
        className={classes.stickToBottom}
      >
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Library" value="library" icon={<AcUnitIcon />} />
        <BottomNavigationAction label="Playlist" value="playlist" icon={<PlayCircleOutlineIcon />} />
        <BottomNavigationAction label="Settings" value="settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </AppBar>
    </React.Fragment>
  );
}
  